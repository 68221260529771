/*
<invite-user-dialog
    :show.sync="inviting"
    :user="invitee"
/>
<reinvite-dialog
    :show.sync="reinviting"
    :user="invitee"
/>
*/
import {
    isBefore
} from "date-fns"

import ui_utilities from "@/nibnut/mixins/UiUtilities"
import addl_profile_utilities from "@/custom/mixins/AddlProfileUtilities"

import ReinviteDialog from "@/nibnut/dialogs/ReinviteDialog"
import InviteUserDialog from "@/custom/dialogs/InviteUserDialog"

const is_user_inviter = {
    mixins: [ui_utilities, addl_profile_utilities],
    components: {
        ReinviteDialog,
        InviteUserDialog
    },
    methods: {
        prepare_invitation () {
            this.record_shell("user").then(record => {
                this.invitee = {
                    ...record,
                    team_name: null,
                    team_school_id: null,
                    expires_in: this.app_context.settings.default_invitation_delay
                }
                if(this.is_regional_admin) this.invitee.role = this.constants("roles", "ROLE_CAPTAIN").id
                this.inviting = true
            })
        },
        resend_invitation (user) {
            this.invitee = user
            this.reinviting = true
        },
        invitation_expired (user) {
            if(!!user && !!user.invitation_expires_at) return isBefore(user.invitation_expires_at, new Date())
            return false
        }
    },
    data () {
        return {
            inviting: false,
            invitee: {},
            reinviting: false
        }
    }
}

export default is_user_inviter
