<template>
    <form-group
        v-bind="form_group_props"
    >
        <template v-slot:read_only>
            <slot name="read_only">
                <label
                    v-if="has_label"
                    class="form-label"
                    :for="identifier"
                >
                    <slot name="label"></slot>
                </label>
                <label
                    class="form-label disabled-field"
                >
                    {{ display_value }}
                </label>
            </slot>
        </template>
        <template v-slot:label><slot name="label"></slot></template>
        <template v-slot:hint><slot name="hint"></slot></template>
        <default-select
            ref="field"
            v-bind="select_props"
            :id="id"
            :name="name"
            :value="value"
            :empty-value="emptyValue"
            :empty-label="emptyLabel"
            :id-field="idField"
            :label-field="labelField"
            :data-source="options"
            :show-all="showAll"
            :ad-hoc="adHoc"
            :ad-hoc-choice-prefix="adHocChoicePrefix"
            :required="required"
            :disabled="disabled"
            @focus="maybe_load_options"
            @search="search"
            @input="pick"
            @create="create"
        >
            <template v-slot:option="{ option, highlight }"><slot name="option" :option="option" :highlight="highlight"></slot></template>
            <template v-slot:right_addon="{ option }"><slot name="right_addon" :option="option"></slot></template>
        </default-select>
    </form-group>
</template>

<script>
import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"
import handles_choices from "@/nibnut/mixins/HandlesChoices"

import FormGroup from "./FormGroup"
import DefaultSelect from "./DefaultSelect"

export default {
    name: "FormSelect",
    mixins: [is_nibnut_component, handles_choices],
    components: {
        FormGroup,
        DefaultSelect
    },
    mounted () {
        this.update_display_value()
    },
    watch: {
        value: "update_display_value"
    },
    methods: {
        maybe_load_options () {
            if(this.showAll) this.load_options()
        },
        autopick (option) {
            this.pick(option[this.idField], this.name, option)
        },
        pick (value, field, option) {
            if(this.editable) this.$emit("input", value, field, option)
        },
        create (label, field) {
            if(this.editable) this.$emit("create", label, field)
        },
        update_display_value () {
            this.display_value = ""
            if(this.value !== this.emptyValue) {
                const assign = (source) => {
                    const chosen_option = source.find(option => option.id === this.value)
                    this.display_value = chosen_option ? chosen_option[this.labelField] : ""
                    this.$emit("displayed", chosen_option)
                }

                if(this.is_remote_source) {
                    this.load_options().then(() => {
                        if(!this.options) return ""
                        assign(this.options)
                    })
                } else assign(this.dataSource)
            }
        }
    },
    computed: {
        has_label () {
            return this.has_slot("label")
        },
        form_group_props () {
            return {
                id: this.id,
                name: this.name,
                value: this.value,
                required: this.required,
                editable: this.editable,
                error: this.error,
                waiting: this.saving
            }
        },
        select_props () {
            const { saving, error, ...select_props } = this.$props
            return select_props
        }
    },
    props: {
        ...DefaultSelect.props,
        editable: {
            type: Boolean,
            default: true
        },
        saving: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: ""
        }
    },
    data () {
        return {
            display_value: ""
        }
    }
}
</script>
